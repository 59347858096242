import { useEffect, useState } from 'react';
import './App.css';
import { Model, Prompt, Report, TalentCombinationResponse, TalentDescriptionResponse } from './types';
import * as api from './api';
import { TalentsList } from './components/talents-list';
import { ReportLoader } from './components/report-loader';
import { ReportViewer } from './components/report-viewer';
import { CombinationsEditor } from './components/combinations-editor';

const models: Model[] = [
  { name: "GPT-4o Mini", model: "gpt-4o-mini", price: { inputPerM: 0.15, outputPerM: 0.6 }, description: "The affordable and intelligent small model for fast, lightweight tasks." },
  { name: "GPT-4o", model: "gpt-4o", price: { inputPerM: 2.5, outputPerM: 10 }, description: "The high-intelligence flagship model for complex, multi-step tasks." },
  { name: "o1-preview", model: "o1-preview-2024-09-12", price: { inputPerM: 15, outputPerM: 60 }, description: "Language models trained with reinforcement learning to perform complex reasoning." },
  // { name: "o1", model: "o1", price: { inputPerM: 15, outputPerM: 60 }, description: "Reasoning model designed to solve hard problems across domains." },
  { name: "o1-mini", model: "o1-mini", price: { inputPerM: 3, outputPerM: 12 }, description: "Fast and affordable reasoning model for specialized tasks." },
];
const prompts: Prompt[] = [
  { name: "Top talents and non-talents", value: "toptalents_nontalents" },
  { name: "Talent combinations", value: "talent_combinations" }
];

function App() {

  const [result, setResult] = useState<TalentCombinationResponse | TalentDescriptionResponse | null>(null);
  const [model, setModel] = useState<Model>(models[0]);
  const [prompt, setPrompt] = useState<Prompt>(prompts[0]);
  const [reportUrl, setReportUrl] = useState<string>("");
  const [ranking, setRanking] = useState<string[]>();
  const [reportType, setReportType] = useState<"individual" | "team">();

  const [error, setError] = useState<string | null>(null);
  const [talentsLoading, setTalentsLoading] = useState<boolean>(false);
  const [promptLoading, setPromptLoading] = useState<boolean>(false);

  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [isShowingSaved, setIsShowingSaved] = useState<boolean>(false);

  const [tab, setTab] = useState<'generate' | 'load' | 'combinations_editor'>('generate');

  async function loadTalents() {
    setTalentsLoading(true);
    setError(null);

    try {
      const r = await api.loadTalents(reportUrl);

      setRanking(r.talents);
      setReportType(r.type);
    } catch (error) {
      setError(`An error occurred: ${error}`);
    } finally {
      setTalentsLoading(false);
    }
  }

  async function handleGenerate() {
    if (!ranking || !reportType) {
      setError("Please load talents first");
      return;
    }

    setPromptLoading(true);
    setError(null);

    try {
      const response = await api.generatePrompt(model.model, prompt.value, ranking, reportType);
      setResult(response);

      setIsShowingSaved(false);
    } catch (error) {
      setError(error as string);
    } finally {
      setPromptLoading(false);
    }
  }

  useEffect(() => {
    if (!selectedReport) {
      return;
    }

    setModel(models.find(m => m.model === selectedReport.model)!);
    setPrompt(prompts.find(p => p.value === selectedReport.prompt)!);
    setReportType(selectedReport.report_type as "individual" | "team");
    setReportUrl(selectedReport.report_url);
    setRanking(selectedReport.ranking);
    setResult(JSON.parse(selectedReport.response_json));

    setIsShowingSaved(true);
  }, [selectedReport]);

  return (
    <div className="bg-gray-100 min-h-screen py-10 px-8">
      <h1 className="text-4xl font-bold text-center mb-10">TT38 GenAI playground</h1>
      <div className="flex justify-center items-center flex-col gap-4">
        <div className="bg-white p-8 rounded-lg shadow-md w-full md:w-full lg:w-3/4 xl:w-4/6">
          <div className="flex mb-4 gap-2 border-b-2">
            <div
              onClick={() => setTab('generate')}
              className={`cursor-pointer px-4 py-2 ${tab === 'generate' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            >
              Generate
            </div>
            <div
              onClick={() => setTab('load')}
              className={`cursor-pointer px-4 py-2 ${tab === 'load' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            >
              Load
            </div>
            <div
              onClick={() => setTab('combinations_editor')}
              className={`cursor-pointer px-4 py-2 ${tab === 'combinations_editor' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            >
              Combinations Editor
            </div>
          </div>
          {tab === 'generate' && (
            <>
              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="team_ranking" className="font-bold mr-4">Talent Report</label>
                <input
                  type="text"
                  id="team_ranking"
                  className="border border-gray-300 rounded-md p-2"
                  value={reportUrl}
                  onChange={(e) => setReportUrl(e.target.value)}
                />
              </div>

              <button onClick={loadTalents} className="bg-blue-500 text-white px-4 py-2 mt-2 mb-6 rounded-md flex items-center" disabled={talentsLoading}>
                {talentsLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                Load Talents
              </button>

              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="model" className="font-bold mr-4">Model</label>
                <div className="relative">
                  <select
                    id="model"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                    value={model.model}
                    onChange={(e) => setModel(models.find(m => m.model === e.target.value)!)}
                  >
                    {models.map(model => <option key={model.model} value={model.model}>{model.name}</option>)}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                  </div>
                </div>
                <p className="text-gray-500 text-sm">{model.description} (input: ${model.price.inputPerM}/million tokens, output: ${model.price.outputPerM}/million tokens)</p>
              </div>

              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="prompt" className="font-bold mr-4">Prompt</label>
                <div className="relative">
                  <select
                    id="prompt"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                    value={prompt.value}
                    onChange={(e) => setPrompt(prompts.find(p => p.value === e.target.value)!)}
                  >
                    {prompts.map(prompt => <option key={prompt.value} value={prompt.value}>{prompt.name}</option>)}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                  </div>
                </div>
              </div>

              <button onClick={handleGenerate} className="bg-blue-500 text-white px-4 py-2 mt-2 rounded-md flex items-center" disabled={promptLoading}>
                {promptLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                Generate
              </button>

              {error && <div className="text-red-500 mt-4">{typeof error === 'string' ? error : JSON.stringify(error)}</div>}
            </>
          )}
          {tab === 'load' && (
            <ReportLoader onSelect={setSelectedReport} />
          )}
          {tab === 'combinations_editor' && (
            <CombinationsEditor />
          )}  
        </div>

        {(ranking || result) && (
          <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 sm:w-full md:w-full lg:w-3/4 xl:w-4/6">
            {ranking && reportType && (
              <div className="bg-white p-8 rounded-lg shadow-md w-full">
                <TalentsList talents={ranking} reportType={reportType} setRanking={setRanking} />
              </div>
            )}

            <div className="bg-white p-8 rounded-lg shadow-md w-full">
              <h2 className="text-3xl font-bold mb-4">Result</h2>
              {isShowingSaved && (
                <p className="text-gray-500 mb-4"><b>Currently showing:</b> {selectedReport?.name}</p>
              )}
              {promptLoading && (
                <div className="flex items-center gap-4 pb-4">
                  <p className="text-gray-500">Generating...</p>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              )}
              {result && ranking && reportType && (
                <ReportViewer 
                  result={result} 
                  model={model} 
                  ranking={ranking} 
                  isSavedReport={isShowingSaved} 
                  reportType={reportType} 
                  reportUrl={reportUrl} 
                  prompt={prompt} 
                  onSave={(name) => {
                    setIsShowingSaved(true);
                    setSelectedReport({
                      name,
                      model: model.model,
                      prompt: prompt.value,
                      ranking: ranking,
                      report_type: reportType,
                      report_url: reportUrl,
                      response_json: JSON.stringify(result),
                      id: "",
                      created_at: ""
                    });
                  }}
                />
              )}
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default App;

