import { useState } from "react";

export function TalentsList({ talents, reportType, setRanking }: { talents: string[], reportType: "individual" | "team", setRanking: (ranking: string[]) => void }) {

    const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
    const [dragPosition, setDragPosition] = useState<'top' | 'bottom' | null>(null);

    return (
        <div className="flex flex-col gap-2">
            <h2 className="text-3xl font-bold">Talents ({reportType === "individual" ? "Individual" : "Team"})</h2>
            <p className="italic text-gray-600 mb-4">Drag to reorder</p>
            {talents && (
                <ul className="text-gray-700">
                    {talents.map((talent, index) => (
                        <li
                            key={index}
                            className={`
                          py-2 border-b ${dragOverIndex !== index ? 'border-gray-300' : ''} px-4 
                          ${getStripedBgColor(index)} 
                          cursor-grab select-none
                          relative
                          ${dragOverIndex === index && dragPosition === 'top' ? 'border-t-1 border-blue-500' : ''}
                          ${dragOverIndex === index && dragPosition === 'bottom' ? 'border-b-1 border-blue-500' : ''}
                        `}
                            draggable={true}
                            onDragStart={(e) => {
                                e.dataTransfer.setData("text/plain", index.toString());
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                                const rect = e.currentTarget.getBoundingClientRect();
                                const middle = rect.top + rect.height / 2;
                                const newPosition = e.clientY <= middle ? 'top' : 'bottom';

                                if (dragOverIndex === index && dragPosition === newPosition) {
                                    return;
                                }

                                setDragOverIndex(index);
                                setDragPosition(newPosition);
                            }}
                            onDragLeave={(e) => {
                                const rect = e.currentTarget.getBoundingClientRect();
                                const mouseEvent = e.nativeEvent;
                                if (
                                    mouseEvent.clientX < rect.left ||
                                    mouseEvent.clientX >= rect.right ||
                                    mouseEvent.clientY < rect.top ||
                                    mouseEvent.clientY >= rect.bottom
                                ) {
                                    if (dragOverIndex === index) {
                                        setDragOverIndex(null);
                                        setDragPosition(null);
                                    }
                                }
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                const fromIndex = Number(e.dataTransfer.getData("text/plain"));
                                const updatedRanking = [...talents];

                                const rect = e.currentTarget.getBoundingClientRect();
                                const middle = rect.top + rect.height / 2;
                                const targetIndex = e.clientY <= middle ? index : index + 1;

                                if (fromIndex === targetIndex || fromIndex === targetIndex - 1) {
                                    return;
                                }

                                const talent = updatedRanking.splice(fromIndex, 1)[0];
                                updatedRanking.splice(targetIndex > fromIndex ? targetIndex - 1 : targetIndex, 0, talent);

                                setRanking(updatedRanking);
                                setDragOverIndex(null);
                                setDragPosition(null);
                            }}
                            onDragEnd={(e) => {
                                e.dataTransfer.clearData();
                                setDragOverIndex(null);
                                setDragPosition(null);
                            }}
                        >
                            {index + 1}. {talent.charAt(0).toUpperCase() + talent.slice(1)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

const getStripedBgColor = (index: number) => {
  if (index < 12) {
    if (index % 2 === 1) {
      return 'bg-green-100';
    }
    return 'bg-green-200';
  }
  if (index > 26) {
    if (index % 2 === 1) {
      return 'bg-red-100';
    }
    return 'bg-red-200';
  }
  if (index % 2 === 1) {
    return 'bg-white';
  }
  return 'bg-gray-100';
}
