import Markdown from "react-markdown";
import { PromptResponse } from "../types";

export function PromptOutput({ content }: { content: PromptResponse<any>['content'] }) {
    return (
        <>
            {Object.entries(content).map(([key, value]) => (
                <div key={key} className="mb-4">
                    <h3 className="text-lg font-bold">{key.charAt(0).toUpperCase() + key.replaceAll("_", " ").slice(1)}</h3>
                    {Array.isArray(value) ? (
                        <ul className="list-disc list-inside text-gray-700">
                            {value.map((item, index) => (
                                <li key={index}><Markdown>{item}</Markdown></li>
                            ))}
                        </ul>
                    ) : (
                        <div className="text-gray-700"><Markdown>{value as string}</Markdown></div>
                    )}
                </div>
            ))}
        </>
    )
}