export type PromptResponse<T> = {
    usage: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
        prompt_token_details: {
            cached_tokens: number;
        };
        completion_token_details: {
            reasoning_tokens: number;
        };
    }
    content: T
    elapsed_time: number;
}

export type TalentCombinationResponse = PromptResponse<{
    talent_combinations: string[];
    description: string;
}>

export type TalentDescriptionResponse = PromptResponse<{
    talent_descriptions: {
        name: string;
        description: string;
        strengths: string[];
        challenges: string[];
        learning_opportunities: string[];
    }[];
}>

export type Model = {
    name: string;
    model: string;
    price: {
        inputPerM: number;
        outputPerM: number;
    },
    description: string;
}

export type Prompt = {
    name: string;
    value: string;
}

export type Report = {
    id: string;
    name: string;
    model: string;
    prompt: string;
    report_type: string;
    report_url: string;
    ranking: string[];
    response_json: string;
    created_at: string;
}

export type TalentCombination = {
    title: string;
    talents: string[];
    non_talents: string[];
    description: string;
    hardcoded: boolean;
}

export const talents = ["analysing", "assessing", "charming", "co-ordinating", "communicating", "competitive", "confident", "connecting", "contextual", "controlling", "developer", "empathic", "flexible", "focused", "holistic", "improver", "inclusive", "individualising", "initiating", "innovative", "inquiring", "just", "mediating", "optimistic", "orderly", "prominent", "reflective", "researching", "responsible", "strategic", "targeted", "trouble-shooter", "value-driven", "visionary"];