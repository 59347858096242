import { PromptResponse, TalentCombination } from "./types";

export async function loadTalents(reportUrl: string): Promise<{ talents: string[], type: "individual" | "team" }> {
    const rankingResponse = await fetch(`${window._env_.BACKEND_URL}/ranking`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ report_url: reportUrl })
    });

    if (rankingResponse.status !== 200) {
        const text = await rankingResponse.text();
        throw new Error(`${rankingResponse.status} ${rankingResponse.statusText}: ${text}`);
    }

    const r = await rankingResponse.json();
    if (!r?.talents) {
        throw new Error("Invalid report URL");
    }

    return r;
}

export async function generatePrompt(model: string, prompt: string, teamRanking: string[], reportType: "individual" | "team"): Promise<PromptResponse<any>> {
    const promptResponse = await fetch(`${window._env_.BACKEND_URL}/prompt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ model, prompt, team_ranking: teamRanking, report_type: reportType }),
      });

      if (promptResponse.status !== 200) {
        const text = await promptResponse.text();
        throw new Error(`${promptResponse.status} ${promptResponse.statusText}: ${text}`);
    }

    const json = await promptResponse.json();
    return json;
}

export async function saveReport(name: string, model: string, prompt: string, ranking: string[], reportType: "individual" | "team", reportUrl: string, responseJson: string): Promise<string> {
    const saveResponse = await fetch(`${window._env_.BACKEND_URL}/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ name, model, prompt, ranking, report_type: reportType, report_url: reportUrl, response_json: responseJson }),
    });

    if (saveResponse.status !== 200) {
        const text = await saveResponse.text();
        throw new Error(`${saveResponse.status} ${saveResponse.statusText}: ${text}`);
    }

    const json = await saveResponse.json();
    return json;
}

export async function loadCombinations(): Promise<TalentCombination[]> {
    const combinationsResponse = await fetch(`${window._env_.BACKEND_URL}/combinations`);
    const json = await combinationsResponse.json();
    return json;
}

export async function saveCombinations(combinations: TalentCombination[]): Promise<void> {
    const combinationsResponse = await fetch(`${window._env_.BACKEND_URL}/combinations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(combinations),
    });

    if (combinationsResponse.status !== 200) {
        const text = await combinationsResponse.text();
        throw new Error(`${combinationsResponse.status} ${combinationsResponse.statusText}: ${text}`);
    }

    return;
}