import { useCallback, useEffect } from "react";

import { useState } from "react";
import { Report } from "../types";

export function ReportLoader({ onSelect }: { onSelect: (report: Report) => void }) {

    const [reports, setReports] = useState<Report[]>([]);
    const [selectedReport, setSelectedReport] = useState<Report | null>(null);

  async function loadReports() {
    const reportsResponse = await fetch(`${window._env_.BACKEND_URL}/reports`);
    const reports = await reportsResponse.json();
    setReports(reports);
  }

  const loadReport = useCallback(() => {
    const report = reports.find(r => r.id === selectedReport?.id);
    if (!report) {
      return;
    }

    onSelect(report);
  }, [reports, selectedReport, onSelect]);

  useEffect(() => {
    loadReports();
  }, []);

  useEffect(() => {
    if (selectedReport) {
      loadReport();
    }
  }, [selectedReport, loadReport]);


    return (
        <div className="flex flex-col mb-4 gap-2">
            <label htmlFor="team_ranking" className="font-bold mr-4">Load Report</label>
            <select
                id="report"
                className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                value={selectedReport?.id}
                onChange={(e) => setSelectedReport(reports.find(r => r.id === e.target.value) || null)}
            >
                <option value="">Select a report</option>
                {reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)}
            </select>
        </div>
    )
}