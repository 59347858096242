import { useState } from "react";
import { Model, Prompt, PromptResponse } from "../types";
import { PromptOutput } from "./prompt-output";
import * as api from "../api";

export function ReportViewer({ 
    result, 
    model, 
    ranking, 
    isSavedReport, 
    reportType,
    reportUrl,
    prompt,
    onSave 
}: { 
    result: PromptResponse<any>, 
    model: Model, 
    ranking: string[], 
    isSavedReport: boolean, 
    reportType: "individual" | "team",
    reportUrl: string,
    prompt: Prompt,
    onSave: (name: string) => void 
}) {

    const [saveName, setSaveName] = useState<string>("");
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    async function handleSave() {
        if (!result || !ranking || !reportType) {
            setError("Please generate a report first");
            return;
        }

        setSaveLoading(true);
        setError(null);

        try {
            await api.saveReport(saveName, model.model, prompt.value, ranking, reportType, reportUrl, JSON.stringify(result));
            onSave(saveName);
            setSaveName("");
        } catch (error) {
            setError(error as string);
        } finally {
            setSaveLoading(false);
        }
    }

    return (
        <div>
            <PromptOutput content={result.content} />
            <hr className="my-4" />
            <p className="text-gray-500">Processing took {result.elapsed_time.toFixed(2)} seconds</p>
            <p>Token usage: {result.usage.prompt_tokens} in prompt (input), {result.usage.completion_tokens} in completion (output)</p>
            <p>Estimated cost: ${(result.usage.prompt_tokens * model.price.inputPerM / 1000000 + result.usage.completion_tokens * model.price.outputPerM / 1000000).toFixed(5)}</p>

            {!isSavedReport && (
                <>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="save_name" className="font-bold mr-4">Name</label>
                        <input
                            type="text"
                            id="save_name"
                            className="border border-gray-300 rounded-md p-2"
                            value={saveName}
                            placeholder={`${ranking?.[0]}, ${ranking?.[1]}, ${ranking?.[2]}...`}
                            onChange={(e) => setSaveName(e.target.value)}
                        />
                    </div>
                    <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 mt-2 rounded-md flex items-center" disabled={saveLoading}>
                        {saveLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>}
                        Save
                    </button>
                    {error && <div className="text-red-500 mt-4">{typeof error === 'string' ? error : JSON.stringify(error)}</div>}
                </>
            )}
        </div>
    )
}