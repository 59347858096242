import { useState, useEffect } from "react";
import * as api from "../api";
import Select from "react-select";
import { talents } from "../types";
interface TalentCombination {
    title: string;
    talents: string[];
    non_talents: string[];
    description: string;
    hardcoded: boolean;
}

const talentOptions = talents.map(t => ({ value: t, label: t.charAt(0).toUpperCase() + t.slice(1) }));

export function CombinationsEditor() {

    const [isFormDirty, setIsFormDirty] = useState(false);

    const [combinations, _setCombinations] = useState<TalentCombination[]>([]);
    const [newCombination, setNewCombination] = useState<TalentCombination>({
        title: "",
        talents: [],
        non_talents: [],
        description: "",
        hardcoded: false
    });

    const setCombinations = (combinations: TalentCombination[]) => {
        // sort by hardcoded false first, then by title
        _setCombinations(combinations.sort((a, b) => (a.hardcoded ? 1 : b.hardcoded ? -1 : a.title.localeCompare(b.title))));
    }

    useEffect(() => {
        api.loadCombinations().then(setCombinations);
    }, []);

    const handleAddCombination = () => {
        const withNewCombination = [...combinations, newCombination];
        setCombinations(withNewCombination);
        setNewCombination({
            title: "",
            talents: [],
            non_talents: [],
            description: "",
            hardcoded: false
        });
        callApi(withNewCombination);
    };

    const handleUpdateCombination = (index: number, field: keyof TalentCombination, value: string | string[]) => {
        const updatedCombinations = [...combinations];
        updatedCombinations[index] = {
            ...updatedCombinations[index],
            [field]: value
        };
        setCombinations(updatedCombinations);
        setIsFormDirty(true);
    };
    
    const callApi = (combinations: TalentCombination[]) => {
        api.saveCombinations(combinations.filter(c => !c.hardcoded));
        setIsFormDirty(false);
    }

    const handleDeleteCombination = (index: number) => {
        const updatedCombinations = combinations.filter((_, i) => i !== index);
        setCombinations(updatedCombinations);
        callApi(updatedCombinations);
    };

    /**
     * Prevent the user from leaving the page if the form is dirty
     */
    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (isFormDirty) {
                event.preventDefault();
                event.returnValue = ''; // Necessary for most browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isFormDirty]);

    const handleFormChange = () => {
        setIsFormDirty(true);
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Talent Combinations Editor</h2>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Add New Combination</h3>
                <div className="grid gap-4">
                    <input
                        type="text"
                        placeholder="Title"
                        className="border p-2 rounded"
                        value={newCombination.title}
                        onChange={e => setNewCombination({ ...newCombination, title: e.target.value })}
                    />
                    <Select
                        value={talentOptions.filter(x => newCombination.talents.includes(x.value))}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(val: any) => {
                            // @ts-ignore: Hydration error
                            setNewCombination({ ...newCombination, talents: val.map(x => x.value) });
                            setIsFormDirty(true);
                        }}
                        options={talentOptions.filter(x => !newCombination.non_talents.includes(x.value))}
                    />
                    <Select
                        value={talentOptions.filter(x => newCombination.non_talents.includes(x.value))}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(val: any) => {
                            // @ts-ignore: Hydration error
                            setNewCombination({ ...newCombination, non_talents: val.map(x => x.value) });
                            setIsFormDirty(true);
                        }}
                        options={talentOptions.filter(x => !newCombination.talents.includes(x.value))}
                    />
                    <textarea
                        placeholder="Description"
                        className="border p-2 rounded"
                        value={newCombination.description}
                        onChange={e => setNewCombination({ ...newCombination, description: e.target.value })}
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={handleAddCombination}
                    >
                        Add Combination
                    </button>
                </div>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border p-2">Title</th>
                            <th className="border p-2">Talents</th>
                            <th className="border p-2">Non-Talents</th>
                            <th className="border p-2">Description</th>
                            <th className="border p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {combinations.map((combination, index) => (
                            <tr key={index}>
                                <td className="border p-2">
                                    <textarea
                                        className={`w-full whitespace-normal break-words resize-none overflow-hidden ${combination.hardcoded ? 'opacity-50' : ''}`}
                                        value={combination.title}
                                        disabled={combination.hardcoded}
                                        onChange={e => {
                                            if (!e.target.value.includes("\n")) {
                                                handleUpdateCombination(index, "title", e.target.value)
                                            }
                                        }}
                                        style={{
                                            wordWrap: 'break-word',
                                            height: 'auto',
                                            minHeight: '2rem'
                                        }}
                                        onInput={e => {
                                            const target = e.target as HTMLTextAreaElement;
                                            target.style.height = 'auto';
                                            target.style.height = target.scrollHeight + 'px';
                                        }}
                                    />
                                </td>
                                <td className="border p-2">
                                    <Select
                                        value={talentOptions.filter(x => combination.talents.includes(x.value))}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isDisabled={combination.hardcoded}
                                        onChange={(val: any) => {
                                            // @ts-ignore: Hydration error
                                            handleUpdateCombination(index, "talents", val.map(x => x.value));
                                        }}
                                        options={talentOptions.filter(x => !combination.non_talents.includes(x.value))}
                                    />
                                </td>
                                <td className="border p-2">
                                    <Select
                                        value={talentOptions.filter(x => combination.non_talents.includes(x.value))}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isDisabled={combination.hardcoded}
                                        onChange={(val: any) => {
                                            // @ts-ignore: Hydration error
                                            handleUpdateCombination(index, "non_talents", val.map(x => x.value));
                                        }}
                                        options={talentOptions.filter(x => !combination.talents.includes(x.value))}
                                    />
                                </td>
                                <td className="border p-2">
                                    <textarea
                                        className={`w-full ${combination.hardcoded ? 'opacity-50' : ''}`}
                                        value={combination.description}
                                        disabled={combination.hardcoded}
                                        rows={4}
                                        onChange={e => handleUpdateCombination(index, "description", e.target.value)}
                                    />
                                </td>
                                <td className="border p-2">
                                    <div className="flex flex-row gap-2">
                                        <button
                                            className={`bg-red-500 text-white px-2 py-1 rounded ${combination.hardcoded ? 'opacity-50' : ''}`}
                                            onClick={() => handleDeleteCombination(index)}
                                            disabled={combination.hardcoded}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            className={`bg-blue-500 text-white px-2 py-1 rounded ${combination.hardcoded || !isFormDirty ? 'opacity-50' : ''}`}
                                            disabled={combination.hardcoded || !isFormDirty}
                                            onClick={() => callApi(combinations)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
